// DisqusComment.tsx
import React, { useState } from 'react';
import { DiscussionEmbed } from 'disqus-react';
import './style/disqus.css';

interface DisqusModalCommentProps {
  identifier: string;
  title: string;
  url?: string; // URL sekarang opsional
  language?: string;
}

const Disqus: React.FC<DisqusModalCommentProps> = ({ identifier, title, url, language = 'en' }) => {
  const [isOpen, setIsOpen] = useState(false);

  const disqusShortname = 'bitlab-my-id'; // Ganti dengan shortname Disqus Anda

  // Jika URL tidak diberikan, gunakan URL default
  const disqusConfig = {
    url:`http://bitlab.my.id/${identifier}`,
    identifier,
    title,
    language: "id",
  };

  const openModal = () => setIsOpen(true);
  const closeModal = () => setIsOpen(false);

  return (
    <div>
      {/* Tombol untuk membuka komentar */}
      <button className="comment-button" onClick={openModal}>
        Komentar/Diskusi
      </button>

      {/* Modal untuk menampilkan komentar */}
      {isOpen && (
        <div className="modal-overlay" onClick={closeModal}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <button className="close-button" onClick={closeModal}>
              ×
            </button>
            <DiscussionEmbed shortname={disqusShortname} config={disqusConfig} />
          </div>
        </div>
      )}
    </div>
  );
};

export default Disqus;
