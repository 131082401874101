import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

interface NextCardProps {
  title: string;
  description: string;
  link: string;
}

const NextCard: React.FC<NextCardProps> = ({ title, description, link }) => {

  // Setel flag di sessionStorage yang menandakan bahwa navigasi terjadi melalui Link
  const handleNavigation = () => {
    sessionStorage.setItem("navigateFromNextCard", "true");
  };

  useEffect(() => {
    // Periksa apakah halaman di-reload atau tidak, jika ya, jangan scroll ke atas
    if (sessionStorage.getItem("navigateFromNextCard") === "true") {
      window.scrollTo(0, 0); // Scroll ke atas
      sessionStorage.removeItem("navigateFromNextCard"); // Hapus flag setelah scroll ke atas
    }
  }, []);

  return (
    <Link to={link} className="next" onClick={handleNavigation}> {/* Membungkus seluruh kartu dalam Link */}
      <div>
        <h2 className='next-title'>{title}</h2>
        <p className='next-description'>{description}</p>
      </div>
    </Link>
  );
};

export default NextCard;
