// Li.tsx
import React, { useEffect } from 'react';
import './style/li.css';

interface LiProps {
  link: string;
  judul: string;
  deskripsi: string;
}

const Li: React.FC<LiProps> = ({ link, judul, deskripsi }) => {
  return (
    <li className="daftar-isi-item">
      <a href={link}>
        <h1 className="judul">{judul}</h1>
        <p className="deskripsi">{deskripsi}</p>
      </a>
    </li>
  );
};

// Custom Hook for Search
export const useSearch = (items: any[], searchText: string, onResults: (resultsExist: boolean) => void) => {
  const filteredItems = items.filter(item =>
    item.judul.toLowerCase().includes(searchText.toLowerCase()) ||
    item.deskripsi.toLowerCase().includes(searchText.toLowerCase())
  );

  useEffect(() => {
    onResults(filteredItems.length > 0);
  }, [filteredItems.length, onResults]);

  return filteredItems;
};

export default Li;
