import React from 'react';
import Card from './Card';

interface CardListProps {
  items: { image: string; title: string; description: string; link: string }[];
}

const CardList: React.FC<CardListProps> = ({ items }) => {
  return (
    <div className="card-list">
      {items.map((item, index) => (
        <Card
          key={index}
          image={item.image}
          title={item.title}
          description={item.description}
          link={item.link}
          onClick={(link) => window.location.href = link}
        />
      ))}
    </div>
  );
};

export default CardList;
