import React from 'react';
import '../../components/style/home.css';
import '../../components/style/article.css'
import Analisis from '../list/Analisis';
import { NextCard, Image, Disqus, HelmetWrapper } from '../../components'

const Page3: React.FC = () => {
    const handleResults = (exists: boolean) => { };
    const post = {
        title: "page-3",
        slug: "Apa-itu-Bullish-dan-bearish",
    };

    return (
        <section className="article">
            <HelmetWrapper
                title="Apa itu Bullish dan Bearish? Memahami Pergerakan Pasar dalam Investasi"
                content="Pelajari apa itu istilah bullish dan bearish dalam dunia investasi, serta bagaimana kedua istilah ini mempengaruhi pergerakan pasar saham, forex, dan crypto."
                kycontent="bullish, bearish, investasi, pergerakan pasar, saham, forex, crypto"
            />

            <div className='article-container'>
                <div className='article-text'>
                    <h1>Apa itu Bullish dan bearish?</h1>
                    <hr />
                    <p>Istilah bullish dan bearish sering digunakan dalam dunia investasi untuk menunjukkan arah pergerakan pasar</p>
                    <Image src='https://bitlabimg.netlify.app/image/bull-and-bear.jpg'
                        alt='Bullish dan bearish?' />
                    <p>Istilah bullish dan bearish diambil dari cara banteng (bull) dan beruang (bear) menyerang. Banteng menyerang dengan mengangkat tanduknya ke atas, melambangkan pasar yang naik. Sebaliknya, beruang menyerang dengan mencakar ke bawah, melambangkan pasar yang turun
                    </p>
                </div>
            </div>

            <div className='article-container'>
                <div className='article-text'>
                    <h3>1. Bullish</h3>
                    <b className='paragraf'>Kondisi ini menunjukkan tren naik</b>
                    <Image
                        src='https://bitlabimg.netlify.app/image/bullish.png'
                        alt='Bullish'
                    />
                    <p>Pasar disebut bullish ketika harga saham atau aset secara umum sedang mengalami kenaikan. Di sini, para investor merasa optimis, dan percaya bahwa harga akan terus naik. Misalnya, jika pasar saham sedang bullish, harga-harga saham akan naik, dan banyak investor yang ingin membeli saham agar mendapatkan keuntungan dari kenaikan harga ini</p>
                </div>
            </div>

            <div className='article-container'>
                <div className='article-text'>
                    <h3>2. bearish</h3>
                    <b className='paragraf'>kondisi ketika pasar mengalami tren turun</b>
                    <Image
                        src='https://bitlabimg.netlify.app/image/bearish.png'
                        alt='Bearish'
                    />
                    <p>Pada situasi ini, harga saham atau aset terus menurun, dan para investor biasanya merasa pesimis. Mereka akan cenderung menjual saham mereka untuk menghindari kerugian yang lebih besar. Misalnya, pada awal pandemi COVID-19, banyak investor merasa pesimis terhadap pasar, sehingga banyak yang menjual aset mereka, dan harga-harga saham menurun tajam</p>
                </div>
            </div>

            <div className="article-container">
                <div className='article-text'>
                    <p>kedua istilah tadi akan seringkita pakai kedepannya jadi ingat saja bullish itu tren naik bearish itu tren turun</p>

                    <h3>Selanjutnya: </h3>
                    <NextCard title="Page 4" description="Ada tiga jenis trend dalam technical analis" link="/tiga-jenis-trend" />
                    <p>Jika ada kesalahan dalam penulisan atau hal lainnya, mohon koreksi. Jangan sungkan, tulis saja di kolom komentar.</p>
                </div>
                <div className='list'>
                    <Analisis searchText="" onResults={handleResults} />
                </div>
            </div>

            <div className="article-container full-width">
                <Disqus
                    identifier={post.slug}
                    title={post.title}
                    url={post.slug}
                />
            </div>
        </section>
    );
};

export default Page3;
