import React, { lazy, Suspense, useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async'; // Impor HelmetProvider
import Sidebar from './components/Sidebar';
import Home from './components/Home';
import SearchPage from './SearchPage';
import Page from './page/Book';
import Ekonomi from './page/kalender/ekonomi';

// Analisis
import Pendahuluan from './page/analisis/pendahuluan';
import Prinsip from './page/analisis/prinsipdasar';
import BullBear from './page/analisis/bull-bear';
import Trend from './page/analisis/trend';

const Analisis = lazy(() => import('./page/list/Analisis'));

const App: React.FC = () => {
  const [darkMode, setDarkMode] = useState<boolean>(false);
  const [searchText, setSearchText] = useState<string>(''); // State untuk pencarian

  useEffect(() => {
    const savedMode = localStorage.getItem("theme");
    if (savedMode === "dark") {
      setDarkMode(true);
      document.body.classList.add("dark");
    }
  }, []);

  const toggleDarkMode = () => {
    setDarkMode((prevMode) => {
      const newMode = !prevMode;
      if (newMode) {
        document.body.classList.add("dark");
        localStorage.setItem("theme", "dark");
      } else {
        document.body.classList.remove("dark");
        localStorage.setItem("theme", "light");
      }
      window.location.reload();
      return newMode;
    });
  };

  const handleResults = (results: any) => {
    console.log(results);
  };

  return (
    <HelmetProvider>
      <Router>
        <Sidebar
          toggleDarkMode={toggleDarkMode}
          darkMode={darkMode}
          onSearch={setSearchText}
        />
        <Suspense fallback={<div>Loading...</div>}>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route
              path="/belajar-Technical-analisis"
              element={<Analisis searchText={searchText} onResults={handleResults} />}
            />
            <Route path="/page" element={<Page searchText={searchText} />} />
            <Route path="/Ekonomi" element={<Ekonomi darkMode={darkMode} />} />
            <Route path="/search" element={<SearchPage searchText={searchText} />} />
            <Route path="/Technical-analisis-adalah" element={<Pendahuluan />} />
            <Route path="/Tiga-prinsip-dasar-untuk-treder" element={<Prinsip />} />
            <Route path="/Apa-itu-Bullish-dan-bearish" element={<BullBear />} />
            <Route path='/tiga-jenis-trend' element={<Trend />} />
          </Routes>
        </Suspense>
      </Router>
    </HelmetProvider>
  );
};

export default App;
