import React from 'react';
import '../../components/style/home.css';
import '../../components/style/article.css'
import Analisis from '../list/Analisis';
import { NextCard, Image, Disqus, HelmetWrapper } from '../../components'

const Page1: React.FC = () => {
    const handleResults = (exists: boolean) => { };
    const post = {
        title: "page-1",
        slug: "Technical-analisis-adalah",
    };

    return (
        <section className="article">
            <HelmetWrapper
                title="Technical Analisis Adalah? Memahami Konsep dan Teknik dalam Analisis Pasar"
                content="Pelajari apa itu analisis teknikal, konsep dasar, dan teknik yang digunakan untuk memprediksi pergerakan pasar saham, forex, dan cryptocurrency. Temukan cara menggunakan chart dan indikator untuk mengambil keputusan investasi yang lebih baik."
                kycontent="analisis teknikal, prediksi pasar, saham, forex, cryptocurrency, indikator teknikal, chart, teknik analisis"
            />

            <div className="article-container">
                <div className="article-text">
                    <h1>Technical Analisis Adalah?</h1>
                    <hr />
                    <div className='konten'>
                        <p>
                            <b>Technical analysis</b> adalah metode yang digunakan untuk memprediksi harga saham atau komoditas dengan cara melihat data yang dihasilkan oleh pasar, seperti pergerakan harga dan jumlah transaksi di masa lalu. Jadi, alih-alih melihat kondisi ekonomi atau nilai sebenarnya dari suatu saham, mereka hanya melihat pola pergerakan harga yang tampak di grafik.
                        </p>
                        <Image
                            src="https://bitlabimg.netlify.app/image/chart-example.png"
                            alt="Example image"
                        />

                        <p>
                            Orang yang menggunakan metode ini disebut technical analyst. Mereka percaya bahwa dengan melihat pola harga dan volume transaksi, mereka bisa memperkirakan apakah harga akan naik atau turun di masa depan.
                        </p>
                        <h3>Contohnya:</h3>
                        <p>
                            Bayangkan kamu berada di sebuah mal. Jika kamu seorang analis fundamental (fundamentalist), kamu akan masuk ke setiap toko, memeriksa harga dan kualitas barang sebelum memutuskan untuk membeli. Tapi kalau kamu seorang <b>technical analyst</b>, kamu tidak masuk ke toko, melainkan hanya mengamati orang-orang yang keluar masuk toko, melihat di mana toko yang ramai dan di mana yang sepi. Dari sana, kamu mencoba memprediksi apakah barang-barang di toko tersebut akan populer atau tidak, tanpa benar-benar melihat barangnya.
                        </p>
                        <p>
                            Jadi, technical analyst hanya melihat pola pergerakan orang (atau harga), bukan nilai sebenarnya dari barangnya.
                        </p>
                    </div>
                </div>
            </div>
            <div className="article-container">
                <div className='article-text'>
                    <h3>Apakah Technical analysis hanya untuk saham atau forex?</h3>
                    <p> technical analysis tidak hanya digunakan untuk saham dan forex. Metode ini bisa digunakan untuk berbagai jenis aset keuangan lainnya. Selain saham dan forex, technical analysis juga dapat diterapkan pada:</p>
                    <ul className='article-li'>
                        <li>Komoditas – seperti emas, perak, minyak, atau bahan mentah lainnya.</li>
                        <li>Obligasi – untuk menganalisis pergerakan harga surat utang.</li>
                        <li>Cryptocurrency – seperti Bitcoin, Ethereum, dan aset digital lainnya.</li>
                        <li>Indeks Pasar – seperti S&P 500 atau IHSG (Indeks Harga Saham Gabungan).</li>
                        <li>Derivatif – seperti opsi (options) atau kontrak berjangka (futures).</li>
                    </ul>
                    <p>
                        Jadi, technical analysis dapat digunakan di berbagai pasar keuangan yang memiliki data historis terkait harga dan volume transaksi.
                    </p>
                    <h3>Selanjutnya: </h3>
                    <NextCard title="Page 2" description="Tiga pemikiran yang menjadi dasar technical analysis" link="/Tiga-prinsip-dasar-untuk-treder" />
                    <p>Jika ada kesalahan dalam penulisan atau hal lainnya, mohon koreksi. Jangan sungkan, tulis saja di kolom komentar.</p>
                </div>
                <div className='list'>
                    <Analisis searchText="" onResults={handleResults} />
                </div>
            </div>

            <div className="article-container full-width">
                <Disqus
                    identifier={post.slug}
                    title={post.title}
                    url={post.slug}
                />
            </div>
        </section>
    );
};

export default Page1;
