// Card.tsx
import React from 'react';
import './style/card.css'; // Import file CSS untuk styling

interface CardProps {
  image: string;
  title: string;
  description: string;
  link: string;
  onClick: (link: string) => void; // Tambahkan prop onClick
}

const Card: React.FC<CardProps> = ({ image, title, description, link, onClick }) => {
  return (
    <div className="card" onClick={() => onClick(link)}> {/* Tambahkan onClick ke div */}
      <img src={image} alt={title} className="card-image" />
      <h3 className="card-title">{title}</h3>
      <p className="card-description">{description}</p>
    </div>
  );
};

export default Card;
