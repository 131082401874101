import React from 'react';
import './style/home.css';
import logo from '../assets/img/logo.webp';

const Home: React.FC = () => {
  return (
    <section className="home">
      <div className="home-container">
        <div className="intro-text">
          <h1>BitLab Dashboard</h1>
          <p>
            Belajar strategi treding untuk pemula, mari kita mulai dari nol sampai bisa, tidak ada yang tidak mungkin jika kamu mau belajar.
          </p>
          <a href="/page" className="explore-btn">Explore Now</a>
        </div>
        <div className="intro-image">
          <img src={logo} alt="BitLab" style={{ width: '400px', height: 'auto' }} />
        </div>

      </div>
    </section>
  );
};

export default Home;
