import React from 'react';
import CardList from '../components/CardList';

interface BookProps {
  searchText: string; // Tambahkan searchText sebagai props
}

const Page: React.FC<BookProps> = ({ searchText }) => {
  const Page = [
    {
      image: 'https://bitlabimg.netlify.app/image/bitlab-book.jpg',
      title: 'Technical Analisis',
      description: 'Analisis teknikal adalah metode untuk menganalisis pergerakan harga dan volume saham untuk mengidentifikasi tren, pola, dan level support serta resistance.',
      link: '/belajar-Technical-analisis',
    },
    

    // Tambahkan lebih banyak buku di sini
  ];

  // Filter buku berdasarkan input pencarian (title atau description)
  const filteredBooks = Page.filter(Page =>
    Page.title.toLowerCase().includes(searchText.toLowerCase()) ||
    Page.description.toLowerCase().includes(searchText.toLowerCase())
  );

  return (
    <section className="page">
      <CardList items={filteredBooks} />
    </section>
  );
};

export default Page;
