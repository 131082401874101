import React, { useEffect } from 'react';
import '../../components/style/home.css';

interface EkonomiProps {
  darkMode: boolean;
}

const Ekonomi: React.FC<EkonomiProps> = ({ darkMode }) => {
  useEffect(() => {
    const container = document.getElementById('tradingview-widget-container');

    if (container) {
      // Mencari elemen skrip yang ada
      let existingScript = container.querySelector('script');

      // Jika tidak ada skrip yang ada, buat yang bar
      if (!existingScript) {
        const script = document.createElement('script');
        script.src = 'https://s3.tradingview.com/external-embedding/embed-widget-events.js';
        script.async = true;

        // Ambil nilai dari Local Storage, jika ada
        const savedTheme = localStorage.getItem('colorTheme');
        const currentTheme = savedTheme ? savedTheme : (darkMode ? "dark" : "light");

        // Parameter widget
        const widgetParams = {
          "width": "100%",
          "height": "100%",
          "colorTheme": currentTheme,
          "isTransparent": true,
          "locale": "id",
          "importanceFilter": "-1,0,1",
          "countryFilter": "ar,au,br,ca,cn,fr,de,in,id,it,jp,kr,mx,ru,sa,za,tr,gb,us,eu"
        };

        // Menambahkan parameter ke elemen skrip
        script.innerHTML = JSON.stringify(widgetParams);

        // Event handler untuk kesalahan skrip
        script.onerror = () => {
          console.error("Error loading TradingView script");
        };

        // Menambahkan skrip ke dalam container
        container.appendChild(script);
      } else {
        // Jika skrip sudah ada, perbarui konfigurasi
        existingScript.innerHTML = JSON.stringify({
          "width": "100%",
          "height": "100%",
          "colorTheme": darkMode ? "dark" : "light",
          "isTransparent": true,
          "locale": "id",
          "importanceFilter": "-1,0,1",
          "countryFilter": "ar,au,br,ca,cn,fr,de,in,id,it,jp,kr,mx,ru,sa,za,tr,gb,us,eu"
        });
      }
    }

    // Menyimpan tema ke Local Storage setiap kali darkMode berubah
    localStorage.setItem('colorTheme', darkMode ? "dark" : "light");

    return () => {
      // Mungkin tidak perlu melakukan apa-apa saat unmount
    };
  }, [darkMode]);

  return (
    <section className="home">
      <div className="tradingview-widget-container" id="tradingview-widget-container">
        <div className="tradingview-widget-container__widget"></div>
        <div className="tradingview-widget-copyright">
          <a href="https://id.tradingview.com/" rel="noopener noreferrer" target="_blank">
            <h4 className="blue-text">Tradingview</h4>
          </a>

        </div>
      </div>
    </section>
  );
};

export default Ekonomi;
