import * as React from 'react';
import Viewer from 'react-viewer';
import './style/article.css';

interface ImageViewerProps {
  src: string;
  alt?: string;
}

const ImageViewer: React.FC<ImageViewerProps> = ({ src, alt = 'Image' }) => {
  const [visible, setVisible] = React.useState(false);

  return (
    <>
      <div className="image-container">
        <img
          src={src}
          alt={alt}
          loading="lazy"
          onClick={() => setVisible(true)}
        />
      </div>
      <Viewer
        visible={visible}
        onClose={() => setVisible(false)}
        images={[{ src, alt }]}
        zoomable
        rotatable
        scalable
      />
    </>
  );
};

export default ImageViewer;
